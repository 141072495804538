<template>
  <div id="school-type-main">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="schoolTypeTitle"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-button type="primary" class="account-btn" @click="CancelMethod" v-if="ownUrlPermission('school-type/create')">新增类型</n-button>
    <div class="account-null-btn"></div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
    />

    <!-- 更改排序弹窗 -->
<div id="StoragePopup">
  <n-modal
    class="custom-card"
    v-model:show="showModal"
    preset="card"
    :style="{'width':'500px'}"
    title="更改排序"
    size="huge"
    :bordered="false"
  >
    <div>
        <n-form
        label-placement="left"
        :label-width="120">
        <n-form-item
          label="类型名称："
          path="schoolTypeName">
        <NInput
          clearable
          v-model:value="schoolTypeName"
        />
       </n-form-item>
        </n-form>
    </div>
    <div class="school-type-bor"></div>
    <div class="storage-btns">
      <NSpace>
        <NButton @click="CancelMethod(0)">取消</NButton>
        <NButton type="primary" @click="ChangeSort">确定</NButton>
      </NSpace>
    </div>
  </n-modal>
</div>
<!-- 更改排序弹窗结束 -->
    <!-- :row-key="rowKey" -->
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { GetSchoolTypeList, PostSchoolTypDelete, PostSchoolTypeCreate } from "@/api/school-type.js";
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "类型",
      key: "title",
    },
    {
      title: '操作',
      width: 300,
      key: "id",
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetSchoolTypeList(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
      }
    })
    .catch((reject) => {});
};
updateList();

//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'primary', onClick: () => SeeDetails(row) }, { default: () => '菜单配置' })
  let arr2 = h(NButton, { text: true, type: 'error', onClick: () => toDelLabel(row) }, { default: () => '删除' })
  if(row.id=="1"){
    // 编辑
    tempArr.push(arr1)
    return tempArr
  }else{
    // 编辑
    tempArr.push(arr1)
    // 删除
    if (ownUrlPermission('school-type/delete')){
      tempArr.push(arr2)
    }
    return tempArr
  }
}

// 菜单配置 
function SeeDetails(row) {
  let id = row.id
  let title = row.title
  router.push({
    path:'/school-type/index/list',
    query:{id,title}
  })
}

const schoolTypeName = ref('')

// 新增学校类型
function ChangeSort(){
  if(schoolTypeName.value !=''){
    let data = {
      'SchoolType[title]':schoolTypeName.value
    }
    PostSchoolTypeCreate(data).then((res) => {
      if (res.code === SUCCESS) {
        showModal.value = false;
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList()
      }
    })
    .catch((reject) => {})
  }else{
    message.info('类型名称不能为空');
  }
}

 // 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {"SchoolType[id]":row.id}
  dialog.warning({
    title: '删除确认',
    content: `确定删除:“${row.title}”？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      PostSchoolTypDelete(data).then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.info(res.msg);
          // 成功后 调用接口刷新页面
          updateList()
        }
      })
      .catch((reject) => {})
    },
    onNegativeClick: () => {}
  });
}

const showModal = ref(false);  // 更改排序弹窗

// 更改排序
function CancelMethod(is, row) {
  showModal.value = is?true:false;
}

const schoolTypeTitle = ref('')
// 搜索
function handleSearch () {
  let data = {"SchoolType[title]":schoolTypeTitle.value}
  updateList(data)
}

// 分页结束-------------------

const getDefaultFormValue = () => ({
  "SchoolType[title]": ""
});
const formValue = reactive(getDefaultFormValue());

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  schoolTypeTitle.value = ''
  handleSearch();
};

</script>

<style scoped>
#school-type-main {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
.storage-btns{
  margin-top: 20px;
  width: 260px;
  float: right;
}
.storage-btns .n-button{
  width: 120px;
}
.school-type-bor{
  border-top: 1px solid #ddd;
  position: absolute;
  left: 0;
  right: 0;
}
</style>